.filters{
    margin-top: -40px;
    width: 100%;
}
.filters div{
    margin-right: 10px;
}
.projects{
    margin-top: 50px;
    width: 100%;
}
.a-project{
    box-shadow: none;
}
.project-title{
    padding-bottom: 10px;
}
.project-description{
    color:#363333;
}
