@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

/* Start */
.read-more{
    margin-top: -40px;
    width: 100%;
}
.peoples, .quick-overview{
    width: 100%;
    margin-bottom: 10px;
}
.peoples div, .peoples .span, .quick-overview div, .quick-overview .span{
    margin-right: 10px;
}
.comment-box{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
}
.section-divider{
    width: 100%;
    margin: 20px 0;
}



/*================================== TIMELINE ==================================*/
/*-- GENERAL STYLES ------------------------------*/
.timeline {
    line-height: 1.4em;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 40px;
}
.timeline h1, .timeline h2, .timeline h3, .timeline h4, .timeline h5, .timeline h6 {
    line-height: inherit;
}
/*----- TIMELINE ITEM -----*/
.timeline-item {
    padding-left: 40px;
    position: relative;
}
.timeline-item:last-child {
    padding-bottom: 0;
}
/*----- TIMELINE MARKER -----*/
.timeline-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;
}
.timeline-marker:before {
    background: #757575;
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.timeline-marker.extend:before {
    border: 7px solid transparent;
    height: 25px;
    width: 25px;
    left: -5px;
}
.timeline-marker:after {
    content: "";
    width: 3px;
    background: #e3eaf0;
    display: block;
    position: absolute;
    top: calc( 50% + 24px);
    bottom: calc(-50% - 14px);
    left: 6px;
}
.timeline-marker.stat:after {
    bottom: calc(-50% - 20px);
}
.timeline-marker.extend:after{
    top: calc( 50% + 34px);
}
.timeline-item:last-child .timeline-marker:after {
    content: none;
}
.timeline-item:not(.period):hover .timeline-marker:before {
    background: transparent;
    border: 3px solid #757575;
}
/*----- TIMELINE CONTENT -----*/
.timeline-stats {
    padding-left: 20px;
}
.timeline-content {
    padding-bottom: 40px;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}
.timeline-content .timeline-time{
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
}
.timeline-content p:last-child {
    margin-bottom: 0;
}
/*---------------------------------------------- MOD: MARKER OUTLINE ----------------------------------------------*/
.marker-outline .timeline-marker:before {
    background: transparent;
    border-color: #757575;
}
.marker-outline .timeline-item:hover .timeline-marker:before {
    background: #757575;
}

