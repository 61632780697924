.page-title{
    margin-top: -50px;
    margin-bottom: 50px;
    display: block;
    width: 100%;
    font-size: 32px;
}
.read-or-hide{
    color: rgb(192,192,192);
    cursor: pointer;
}